import React from 'react'
import PropTypes from 'prop-types'

class OauthButton extends React.Component {

  constructor(props) {
    super(props)

    this.state = { popupWindow: null, popupWindowTimer: null }
  }

  componentDidMount = () => {
    window.addEventListener('message', this.handleMessageFromPopup)
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this.handleMessageFromPopup)

    if (this.state.popupWindowTimer) {
      clearInterval(this.state.popupWindowTimer)
    }

    if (this.state.popupWindow) {
      this.state.popupWindow.close()
    }
  }

  handleMessageFromPopup = (event) => {
    const { onAuthentication, onError } = this.props

    if (event.source && event.source.name === 'oauth-flow') {
      const { popupWindow, popupWindowTimer } = this.state
      if (popupWindow) {
        if (popupWindowTimer) {
          clearInterval(popupWindowTimer)
        }

        popupWindow.close()
      }

      this.setState({ popupWindow: null, popupWindowTimer: null })

      if (event.data.success) {
        onAuthentication(event.data.nextLocation)
      } else {
        onError(event.data.error)
      }
    }
  }

  startOauthFlow = () => {
    const width = 1024
    const height = 768
    const top = (screen.height) ? (screen.height - height) / 2 : 0
    const left = (screen.width) ? (screen.width - width) / 2 : 0
    const popupWindow = window.open(this.oauthUrl(), 'oauth-flow', `width=${width},height=${height},top=${top},left=${left},toolbar=0,menubar=0,location=0`)
    if (popupWindow) {
      const popupWindowTimer = setInterval(() => {
        if (popupWindow.closed) {
          // oauth was canceled by closing the popup
          clearInterval(popupWindowTimer)
          this.setState({ popupWindowTimer: null })
        }
      }, 1000)

      this.setState({ popupWindow, popupWindowTimer })
    }
  }

}

OauthButton.propTypes = {
  onAuthentication: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

export default OauthButton
