import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink, withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Snackbar from '@material-ui/core/Snackbar'

import PasswordsApi from '../../api/passwords_api'

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2)
  },
  notification: {
    maxWidth: '100%'
  }
})

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: '', formSubmitted: false, errors: {}, notification: '' }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    PasswordsApi.create({ email: this.state.email }).then(() => {
      this.setState({ errors: {}, notification: 'You will receive an email with instructions on how to reset your password in a few minutes.' })
    }).catch((error) => {
      this.setState({ errors: error.response.data })
    }).then(() => {
      this.setState({ formSubmitted: false })
    })
  }

  render() {
    const { classes } = this.props
    const { email, errors, formSubmitted, notification } = this.state
    return (
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <form name="session" onSubmit={this.handleSubmit}>
                <Typography variant="h5" paragraph>Forgot Your Password?</Typography>

                <Snackbar message={notification} className={classes.notification} open={!!notification}/>

                <TextField
                  name="email" label="Your Email Address" margin="dense" type="email" fullWidth required
                  error={!!errors.email} helperText={errors.email}
                  disabled={formSubmitted} value={email}
                  onChange={this.handleChange('email')}
                />

                <FormControl fullWidth margin="dense">
                  <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.formSubmitted}>
                    Reset
                  </Button>
                  <Button component={RouterLink} to="/sign_in" className={classes.button}>Sign In</Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

New.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(withStyles(styles)(New))
