import apiHelper from './../../app/api/api_helper'

const api = apiHelper.connection()

const PasswordsApi = {
  create: (user) => {
    return api.post('/users/password', { data: { type: 'users', attributes: user } })
  },

  update: (user) => {
    return api.patch('/users/password', { data: { type: 'users', attributes: user } })
  }
}

export default PasswordsApi
