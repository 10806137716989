import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Helptip from './helptip'

const styles = (theme) => {
  return {
    headline: {
      textTransform: 'capitalize'
    },
    title: {
      textTransform: 'capitalize'
    },
    tooltip: {
      marginLeft: theme.spacing(1),
    },
    actions: {
      marginLeft: theme.spacing(4),
      display: 'inline-block',
      lineHeight: 'normal'
    }
  }
}

const TitleBar = (props) => {
  const { title, children, variant, component, helptip, classes } = props

  let helptipComponent = null
  if (helptip) {
    helptipComponent = (<span className={classes.tooltip}><Helptip>{helptip}</Helptip></span>)
  }

  if (variant === 'h5' || _.isNil(variant)) {
    return (
      <Typography variant='h5' component={component || 'p'} gutterBottom>
        <span className={classes.headline}>{title}</span>
        {helptipComponent}
        <span className={classes.actions}>
          {children}
        </span>
      </Typography>
    )
  } else {
    return (
      <Typography variant={variant} component={component || 'p'} gutterBottom>
        <span className={classes.title}>{title}</span>
        {helptipComponent}
        <span className={classes.actions}>
          {children}
        </span>
      </Typography>
    )
  }
}

TitleBar.propTypes = {
  title: PropTypes.any.isRequired,
  variant: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.string,
  classes: PropTypes.object.isRequired,
  helptip: PropTypes.any
}

TitleBar.defaultProps = {
  help: false
}

export default withStyles(styles)(TitleBar)
