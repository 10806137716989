/* eslint-disable babel/new-cap */
const Intercom = {
  trackEvent: (event, properties) => {
    if (Intercom.isEnabled()) {
      window.Intercom('trackEvent', event, properties)
    }
  },

  show: () => {
    if (Intercom.isEnabled()) {
      window.Intercom('show')
    }
  },

  isEnabled: () => {
    return window.Intercom !== undefined
  }
}

export default Intercom
