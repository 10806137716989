import React from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

import SessionsApi from '../../api/sessions_api'

import GoogleButton from '../base/google_button'
import FacebookButton from '../base/facebook_button'
import Divider from '../base/divider'

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2)
  }
})

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: '', password: '', formSubmitted: false, error: '' }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    SessionsApi.create(
      {
        email: this.state.email,
        password: this.state.password
      }
    ).then((response) => {
      window.location = response.headers.location || '/'
      return true
    }).catch((error) => {
      console.log(error.response)
      if (error.response) {
        this.setState({ error: error.response.data.Error, formSubmitted: false })
      } else {
        this.setState({ error: error.toString(), formSubmitted: false })
        Raven.captureException(error, { extra: error })
      }
    })
  }

  handleOauth = (nextLocation) => {
    this.setState({ formSubmitted: true })

    window.location = nextLocation || '/'
    return true
  }

  handleOauthError = (error) => {
    if (error) {
      this.setState({ error, formSubmitted: false })
    }
  }

  isInError = () => {
    return this.state.error !== ''
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <form name="session" onSubmit={this.handleSubmit}>
                <Typography variant="h5" paragraph>Sign In</Typography>

                <FormControl fullWidth margin="dense">
                  <GoogleButton buttonText="Continue With Google" onAuthentication={this.handleOauth} onError={this.handleOauthError}/>
                </FormControl>

                <Divider/>

                <FormControl fullWidth margin="dense">
                  <FacebookButton buttonText="Continue With Facebook" onAuthentication={this.handleOauth} onError={this.handleOauthError}/>
                </FormControl>

                <Divider/>

                {this.state.error && <Typography color="error" paragraph>{this.state.error}</Typography>}

                <TextField
                  name="email" label="Your Email Address" margin="dense" type="email" error={this.isInError()}
                  disabled={this.state.formSubmitted} fullWidth required value={this.state.email}
                  onChange={this.handleChange('email')}
                />
                <TextField
                  name="password" label="Password" margin="dense" type="password" error={this.isInError()}
                  disabled={this.state.formSubmitted} fullWidth required value={this.state.password}
                  onChange={this.handleChange('password')}
                />

                <FormControl fullWidth margin="dense">
                  <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.formSubmitted}>
                    Sign In
                  </Button>
                  <Button component={RouterLink} to="/users/password/new" className={classes.button}>Forgot Password?</Button>
                  <Button component={RouterLink} to="/sign_up" className={classes.button}>Sign Up</Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

New.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(New)
