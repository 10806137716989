import React from 'react'
import PropTypes from 'prop-types'
import { Divider as MuiDivider, withStyles } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  typography: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5)
  }
})

class Divider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container spacing={0}>
        <Grid item xs={4} sm={5} md={5} lg={5}>
          <MuiDivider className={classes.divider}/>
        </Grid>

        <Grid item xs={4} sm={2} md={2} lg={2}>
          <Typography align='center' variant='body2' className={classes.typography}>Or</Typography>
        </Grid>

        <Grid item xs={4} sm={5} md={5} lg={5}>
          <MuiDivider className={classes.divider}/>
        </Grid>
      </Grid>
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Divider)
