import axios from 'axios'
import dasherize from 'dasherize'
import camelize from 'camelize'
import Promise from 'promise-polyfill'

const EXPECTED_STATUS_CODES = [401, 402, 404, 410, 422]

const ApiHelper = {

  getCSRFToken: () => {
    const token = document.querySelector('meta[name="csrf-token"]')
    if (token && (token instanceof window.HTMLMetaElement)) {
      return token.content
    }
    return null
  },

  connection: () => {
    const instance = axios.create({
      headers: {
        'X-CSRF-Token': ApiHelper.getCSRFToken(),
        'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      transformResponse: [].concat(
        axios.defaults.transformResponse,
        ApiHelper.transformJSONAPIResponse,
      )
    })

    instance.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (!!error.response && EXPECTED_STATUS_CODES.includes(error.response.status)) {
        // return error to be used on UI
        return Promise.reject(error)
      } else {
        // such error will be handled by Promise._unhandledRejectionFn handler
        const err = Promise.reject(error)
        err.response = error.response
        throw err
      }
    })

    return instance
  },

  transformJSONAPIResponse: (data, headers) => {
    if (headers['content-type'] !== undefined && headers['content-type'].startsWith('application/vnd.api+json')) {
      if (data['meta']) {
        const result = {}
        result['meta'] = data['meta']
        result['data'] = ApiHelper.transformJSONAPIResponse({ data: data['data'], included: data['included'] }, headers)
        return result
      } else if (data['data']) {
        if (Array.isArray(data['data'])) {
          const result = {}
          data['data'].forEach((object) => {
            const target = ApiHelper.transformJSONAPIResponse({ data: object, included: data['included'] }, headers)

            result[target.id] = target
          })

          return result
        } else {
          const result = camelize(data['data'].attributes)
          result.id = data['data'].id

          if (data['data'].relationships !== undefined) {
            Object.keys(data['data'].relationships).forEach((relationshipName) => {
              const relationship = data['data'].relationships[relationshipName]
              if (relationship['data'] !== undefined && Array.isArray(relationship['data'])) {
                const relationshipObjects = relationship['data'].map((relationshipItem) => {
                  const relationshipObject = data['included'].find((object) => {
                    return object.type === relationshipItem.type && object.id === relationshipItem.id
                  })

                  return ApiHelper.transformJSONAPIResponse({ data: relationshipObject, included: data['included'] }, headers)
                })

                result[camelize(relationshipName)] = relationshipObjects
              } else if (relationship['data'] !== undefined && relationship['data'] !== null) {
                const relationshipObject = data['included'].find((object) => {
                  return object.type === relationship['data'].type && object.id === relationship['data'].id
                })

                result[camelize(relationshipName)] = ApiHelper.transformJSONAPIResponse({ data: relationshipObject, included: data['included'] }, headers)
              }
            })
          }

          return result
        }
      } else {
        const result = {}

        const errors = data['errors']
        if (errors && errors.length) {
          errors.forEach((item) => {
            if (item.source && item.source.attribute) {
              result[item.source.attribute] = item.detail
            } else if (item.attributes) {
              result['data'] = ApiHelper.transformJSONAPIResponse({ data: item }, headers)
            } else {
              // todo currently storing only last error form the response (if error in response has no attribute name)
              result['_error'] = item.detail || item.title
            }
          })

          return camelize(result)
        } else {
          return result['_error'] = data
        }
      }
    } else {
      return data
    }
  },

  jsonApi: {
    // todo: import-ui -> remove this in favor of transformRequest hook on axios (similar to transformResponse)
    formatObject: (type, data) => {
      // todo: import-ui --> looks like incorrect jsonapi body.. according to the spec there is no '_jsonapi' attribute/field
      // answer --> creation don't working without this flag
      // todo: import-ui --> i don't believe you! look at the file_uploads api -> it doesn't add any _jsonapi and works perfectly
      return {
        data: {
          type: dasherize(type), attributes: dasherize(data)
        }
      }
    }
  }
}

export default ApiHelper
