import React from 'react'
import PropTypes from 'prop-types'
import Promise from 'promise-polyfill'

import Raven from 'raven-js'
import { withStyles } from '@material-ui/core/styles'
import { UserContext } from '../../contexts'
import ErrorBar from './error_bar'

const styles = theme => ({
  root: {
    display: 'flex'
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, showNotification: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Raven.captureException(error, { extra: errorInfo })
  }

  componentWillMount = () => {
    Promise._unhandledRejectionFn = (error) => {
      if (error.response && error.response.status === 498) {
        this.context.connectionCredentialsExpired(Object.values(error.response.data)[0])
      } else {
        this.setState({ showNotification: true })
        Raven.captureException(error, { extra: error })
      }
    }
  }

  onNotificationBarClose = () => {
    this.setState({ showNotification: false })
  }

  render() {
    const { classes } = this.props

    if (this.state.error) {
      return (
        <div>
          <p>We're sorry — something's gone wrong.</p>
          <p>Our team has been notified. Please, contact us for help.</p>
        </div>
      )
    } else {
      return (<div className={classes.root}>
        {this.props.children}
        <ErrorBar open={this.state.showNotification} onClose={this.onNotificationBarClose}/>
      </div>)
    }
  }
}

ErrorBoundary.contextType = UserContext

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
}

export default withStyles(styles)(ErrorBoundary)
