import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import RegistrationsApi from '../../api/registrations_api'

import GoogleButton from '../base/google_button'
import FacebookButton from '../base/facebook_button'
import Divider from '../base/divider'

const styles = theme => ({
  flex: {
    flex: 1
  },
  header: {
    marginBottom: theme.spacing(4)
  },
  logo: {
    height: 28
  },
  button: {
    marginTop: theme.spacing(2)
  },
  formLabel: {
    color: theme.palette.text.secondary
  }
})

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = { fullName: '', email: '', password: '', newsletter: false, formSubmitted: false, error: props.error || '' }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleChangeNewsletter = (event) => {
    this.setState({ newsletter: event.target.checked })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    RegistrationsApi.create(
      {
        'full-name': this.state.fullName,
        'email': this.state.email,
        'password': this.state.password,
        'newsletter': this.state.newsletter
      }
    ).then((response) => {
      window.location = response.headers.location || '/'
      return true
    }).catch((error) => {
      const errors = _.map(error.response.data, ((error) => {
        return error
      }))
      this.setState({ error: _.join(errors, ', '), formSubmitted: false })
    })
  }

  handleOauth = (nextLocation) => {
    this.setState({ formSubmitted: true })

    window.location = nextLocation || '/'
    return true
  }

  handleOauthError = (error) => {
    if (error) {
      this.setState({ error, formSubmitted: false })
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <form name="registraion" onSubmit={this.handleSubmit}>
                <Typography variant="h5" paragraph>Sign Up For CSV Loader</Typography>

                <FormControl fullWidth margin="dense">
                  <GoogleButton buttonText="Sign Up With Google" onAuthentication={this.handleOauth} onError={this.handleOauthError}/>
                </FormControl>

                <Divider/>

                <FormControl fullWidth margin="dense">
                  <FacebookButton buttonText="Sign Up With Facebook" onAuthentication={this.handleOauth} onError={this.handleOauthError}/>
                </FormControl>

                <Divider/>

                {this.state.error && <Typography color="error" paragraph>{this.state.error}</Typography>}
                <TextField
                  name="fullName" label="Your Name" margin="dense" disabled={this.state.formSubmitted} fullWidth required value={this.state.fullName}
                  onChange={this.handleChange('fullName')}
                />
                <TextField
                  name="email" label="Your Email Address" margin="dense" type="email" disabled={this.state.formSubmitted} fullWidth required
                  value={this.state.email}
                  onChange={this.handleChange('email')} autoComplete="off"
                />
                <TextField
                  name="password" label="Password" margin="dense" type="password" disabled={this.state.formSubmitted} fullWidth required
                  value={this.state.password}
                  onChange={this.handleChange('password')} autoComplete="new-password"
                />

                <FormControl fullWidth margin="dense">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={this.state.formSubmitted}
                          checked={this.state.newsletter}
                          onChange={this.handleChangeNewsletter}
                          value="newsletter"
                          color="primary"
                        />
                      }
                      label="Send me occasional product updates (approx. once per month)"
                      classes={{ label: classes.formLabel }}
                    />
                  </FormGroup>
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.formSubmitted}>
                    Sign Up
                  </Button>
                  <Button component={RouterLink} to="/sign_in" className={classes.button}>Sign In</Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

New.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(New)
