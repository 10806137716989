import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MaterialTooltip from '@material-ui/core/Tooltip'

import Icon from '@material-ui/icons/HelpOutline'

// Arrow added using code from https://material-ui.com/demos/tooltips/#customized-tooltips

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      'top': 0,
      'left': 0,
      'marginTop': '-0.95em',
      'width': '3em',
      'height': '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.95em',
      'width': '3em',
      'height': '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      'left': 0,
      'marginLeft': '-0.95em',
      'height': '3em',
      'width': '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      'right': 0,
      'marginRight': '-0.95em',
      'height': '3em',
      'width': '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  }
}

const styles = theme => ({
  icon: {
    fontSize: theme.typography.subtitle1.fontSize,
    verticalAlign: 'middle',
    color: theme.palette.grey[500]
  },
  tooltip: {
    fontSize: theme.typography.body2Next.fontSize
  },
  popper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    'position': 'absolute',
    'fontSize': 6,
    'width': '3em',
    'height': '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
})

class Helptip extends React.Component {
  state = { arrowRef: null }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node
    })
  }

  render = () => {
    const { children, classes } = this.props

    return (
      <MaterialTooltip
        title={
          <React.Fragment>
            {children}
            <span className={classes.arrow} ref={this.handleArrowRef}/>
          </React.Fragment>
        }
        classes={{ popper: classes.popper, tooltip: classes.tooltip }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef
              }
            }
          }
        }}
        disableFocusListener interactive
        placement='top'
      >
        <Icon className={classes.icon}/>
      </MaterialTooltip>
    )
  }
}

Helptip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
}

export default withStyles(styles)(Helptip)
