import React from 'react'
import PropTypes from 'prop-types'

import { Button, withStyles } from '@material-ui/core'
// eslint-disable-next-line import/no-unresolved
import GoogleLogo from 'images/google_logo_36.svg'
import OauthButton from './oauth_button'

const styles = () => ({
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: 0,
    backgroundColor: '#4285F4 !important',
    border: '1px solid #4285F4 !important',
    '&:active': {
      backgroundColor: '#3367d6 !important',
      borderColor: '#3367d6 !important'
    }
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 15
  },
  logo: {
    padding: 15,
    height: 17,
    width: 17,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: 'white'
  }
})

class GoogleButton extends OauthButton {

  oauthUrl = () => {
    return '/authorization/google'
  }

  render() {
    const { classes, buttonText } = this.props
    return (
      <React.Fragment>
        <Button
          variant="contained" color="primary" disableFocusRipple onClick={this.startOauthFlow}
          className={classes.button} classes={{ startIcon: classes.startIcon }}
          startIcon={<img src={GoogleLogo} alt='Google logo' className={classes.logo}/>}
        >
          {buttonText}
        </Button>
      </React.Fragment>
    )
  }
}

GoogleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string
}

GoogleButton.defaultProps = {
  buttonText: 'Login with Google'
}

export default withStyles(styles)(GoogleButton)
