import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Button from '@material-ui/core/Button'

// eslint-disable-next-line import/no-unresolved
import FacebookLogo from 'images/facebook_logo_36.svg'
import OauthButton from './oauth_button'

const styles = () => ({
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: 0,
    backgroundColor: '#3B5998 !important',
    border: '1px solid #3B5998 !important'
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 15
  },
  logo: {
    height: 47,
    width: 47,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: 'white'
  }
})

class FacebookButton extends OauthButton {

  oauthUrl = () => {
    return '/authorization/facebook'
  }

  render() {
    const { classes, buttonText } = this.props

    return (
      <Button
        variant="contained" color="primary" disableFocusRipple onClick={this.startOauthFlow}
        className={classes.button} classes={{ startIcon: classes.startIcon }}
        startIcon={<img src={FacebookLogo} alt='Facebook logo' className={classes.logo}/>}
      >
        {buttonText}
      </Button>
    )
  }
}

FacebookButton.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string
}

FacebookButton.defaultProps = {
  buttonText: 'Login with Facebook'
}

export default withStyles(styles)(FacebookButton)
