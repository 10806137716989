import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import ToolsApi from '../../../app/api/tools_api'
import TitleBar from '../../../app/components/base/title_bar'

const styles = theme => ({
  columnIcon: {
    width: 50
  },
  columnLabel: {
    width: 1,
    whiteSpace: 'nowrap'
  },
  columnName: {
    width: 1,
    whiteSpace: 'nowrap',
    // todo replace with https://material-ui.com/system/typography/#font-family after migrating to material-ui v4
    fontFamily: '"Lucida Console", Monaco, monospace'
  },
  toolIcon: {
    height: 50,
    width: 50,
    borderRadius: 0
  },
  toolLogoWrapper: {
    height: 50
  },
  toolLogo: {
    height: '100%'
  }
})

class Index extends React.Component {
  constructor(props) {
    super(props)

    ToolsApi.index().then(({ data }) => {
      this.setState({ tools: data })
    })

    this.state = {
      tools: null
    }
  }

  render = () => {
    const { tools } = this.state
    const { classes } = this.props

    if (_.isNil(tools)) {
      return null
    } else {
      const sortedToolKeys = _.sortBy(Object.keys(tools), key => tools[key].label.toLowerCase())

      return (
        <React.Fragment>
          <TitleBar title="Supported Apps"/>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnIcon} padding='checkbox'>Icon</TableCell>
                <TableCell className={classes.columnLabel}>Name</TableCell>
                <TableCell className={classes.columnName}>Code</TableCell>
                <TableCell className={classes.columnLogo}>Logo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedToolKeys.map(key => (
                <TableRow key={key}>
                  <TableCell className={classes.columnIcon} padding='checkbox'>
                    <Avatar
                      aria-label={`${tools[key].label} Icon`} alt={`${tools[key].label} Icon`} src={tools[key].iconLargeUrl} className={classes.toolIcon}
                    />
                  </TableCell>
                  <TableCell className={classes.columnLabel}>
                    <Typography variant='subtitle1'>{tools[key].label}</Typography>
                  </TableCell>
                  <TableCell className={classes.columnName}>{tools[key].name}</TableCell>
                  <TableCell>
                    <div className={classes.toolLogoWrapper}>
                      <img src={tools[key].logoLargeUrl} title={`${tools[key].label} Logo`} className={classes.toolLogo}/>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </React.Fragment>
      )
    }
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Index)
