import apiHelper from './../../app/api/api_helper'

const api = apiHelper.connection()

const ButtonsApi = {
  get: (key, dataSource = null) => {
    return api.get(`/buttons/${key}`, { params: { data_source: dataSource } })
  }
}

export default ButtonsApi
