import React from 'react'

export const UserContext = React.createContext({
  user: null,
  stripePublishableKey: null,

  onSubscriptionChange: () => {
  },

  onCreditPurchase: () => {
  },

  connectionCredentialsExpired: () => {
  },

  showNotification: () => {
  },

  onHideWelcomeDialog: () => {
  },

  quotaWillOverused: () => {
  },

  onCreateSync: () => {
  },

  currentSubscription: () => {

  },
  dataBackupQuota: (dataBackupId) => {
  },

  importQuota: (importId) => {
  },

  reportQuota: (importId) => {
  },

  exportQuota: (importId) => {
  }
})
