import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink, withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import PasswordsApi from '../../api/passwords_api'

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2)
  }
})

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = { password: '', formSubmitted: false, errors: {}}
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ formSubmitted: true })
    const { password } = this.state

    PasswordsApi.update({ password }).then(() => {
      window.location = '/'
      return true
    }).catch((error) => {
      this.setState({ errors: error.response.data, formSubmitted: false })
    })
  }

  render() {
    const { classes } = this.props
    const { password, errors, formSubmitted } = this.state
    return (
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <form name="session" onSubmit={this.handleSubmit}>
                <Typography variant="h5" paragraph>Change Your Password</Typography>

                <TextField
                  name="password" label="New Password" margin="dense" type="password" fullWidth required
                  error={!!errors.password} helperText={errors.password}
                  disabled={formSubmitted} value={password}
                  onChange={this.handleChange('password')}
                />

                <FormControl fullWidth margin="dense" error={!!errors.resetPasswordToken}>
                  {errors.resetPasswordToken && <FormHelperText>{errors.resetPasswordToken}</FormHelperText>}
                  <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.formSubmitted}>
                    Save
                  </Button>
                  <Button component={RouterLink} to="/sign_in" className={classes.button}>Sign In</Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.any.isRequired
}

export default withRouter(withStyles(styles)(Edit))
