import apiHelper from './api_helper'

const API_URL = '/tools'
const api = apiHelper.connection()

const ToolsApi = {
  index: () => api.get(API_URL),

  get: id => api.get(`${API_URL}/${id}`)
}

export default ToolsApi
