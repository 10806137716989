import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import _ from 'lodash'
import queryString from 'query-string'

import Button from '@material-ui/core/Button'
import SectionDivider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'

import Logo from 'images/logo-black.png'

import ButtonsApi from '../../api/buttons_api'

import GoogleButton from '../base/google_button'
import FacebookButton from '../base/facebook_button'
import OptionDivider from '../base/divider'

import AuthenticationDialog from './base/authentication_dialog'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8)
  },
  card: {
    border: '1pt solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    padding: theme.spacing(2)
  },
  cardActionButton: {
    marginLeft: theme.spacing(1),
  },
  toolIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  toolIcon: {
    margin: theme.spacing(2),
    width: 50,
    height: 50,
    borderRadius: 0
  },
  link: {
    cursor: 'pointer'
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    paddingTop: 25,
    height: 0
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  footer: {
    display: 'flex',
    alignItems: 'center'
  },
  footerLogoContainer: {
    flex: 1,
    height: 28
  },
  footerLogo: {
    height: 28
  }
})

class Show extends React.Component {
  constructor(props) {
    super(props)

    const query = queryString.parse(this.props.location.search)
    const dataSource = query.data_source

    ButtonsApi.get(props.match.params.key, dataSource).then(({ data }) => {
      this.setState({ button: data })
    })

    this.state = {
      button: null,
      authenticationDialogOpen: false,
      howItWorksDialogOpen: false
    }
  }

  handleAuthenticationDialogOpen = () => {
    this.setState({ authenticationDialogOpen: true })
  }

  handleAuthenticationDialogClose = () => {
    this.setState({ authenticationDialogOpen: false })
  }

  handleAuthenticate = () => {
    const { button } = this.state

    window.location = button.afterSignInUrl
  }

  handleError = (error) => {
    if (error) {
      this.setState({ error })
    }
  }

  handleHowItWorksDialogOpen = (event) => {
    event.preventDefault()
    this.setState({ howItWorksDialogOpen: true })
  }

  handleHowItWorksDialogClose = () => {
    this.setState({ howItWorksDialogOpen: false })
  }

  renderTitleSection = (button) => {
    let titleSection = null
    switch (button.feature) {
      case 'app_import':
        titleSection = (
          <React.Fragment>
            <Typography variant="h5" paragraph>
              Continue to CSV Loader
            </Typography>
            <Typography paragraph>
              {button.toolDescription.label} and CSV Loader partnered to provide a tool that lets you import data to {button.toolDescription.label} directly from
              other apps or from CSV files. <Link href="#" onClick={this.handleHowItWorksDialogOpen}>Learn more</Link>.
            </Typography>
            {button.toolDescription.freeImports && <Typography paragraph>Any data import into {button.toolDescription.label} is free.</Typography>}
          </React.Fragment>
        )

        break
      case 'sync':
        titleSection = (
          <React.Fragment>
            <Typography variant="h5" paragraph>
              Continue to CSV Loader
            </Typography>
            <Typography paragraph>
              {button.toolDescription.label} and CSV Loader partnered to offer a two-way data sync with Salesforce. Data sync will automatically copy any updates
              on Organizations, People or Deals between {button.toolDescription.label} and Salesforce.
            </Typography>
            {button.toolDescription.freeSyncs && <Typography paragraph>Data sync service is free for {button.toolDescription.label} users.</Typography>}
          </React.Fragment>
        )

        break
      default:
        throw `Unexpected feature for button[id:${button.id}]: ${button.feature}`
    }

    return titleSection
  }

  render = () => {
    const { classes } = this.props
    const { button, error, authenticationDialogOpen, howItWorksDialogOpen } = this.state

    if (_.isNil(button)) {
      return null
    } else {
      return (
        <Grid container justify='center' className={classes.root}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div className={classes.card}>
              <CardContent>
                {this.renderTitleSection(button)}

                <SectionDivider className={classes.divider}/>

                {!!error && <Typography color="error" paragraph>{error}</Typography>}

                <FormControl fullWidth margin="dense">
                  <GoogleButton buttonText="Continue With Google" onAuthentication={this.handleAuthenticate} onError={this.handleError}/>
                </FormControl>

                <OptionDivider/>

                <FormControl fullWidth margin="dense">
                  <FacebookButton buttonText="Continue With Facebook" onAuthentication={this.handleAuthenticate} onError={this.handleError}/>
                </FormControl>

                <OptionDivider/>

                <FormControl fullWidth margin="dense">
                  <Button variant="contained" color='primary' fullWidth onClick={this.handleAuthenticationDialogOpen}>
                    Continue with email
                  </Button>
                </FormControl>
              </CardContent>
            </div>

            <Toolbar disableGutters className={classes.footer}>
              <div className={classes.footerLogoContainer}>
                <img src={Logo} className={classes.footerLogo} alt="CSV Loader"/>
              </div>

              <Button key='link-privacy' size='small' href='https://www.csv-loader.com/privacy-policy'>Privacy</Button>
              <Button key='link-terms' size='small' href='https://www.csv-loader.com/terms-of-service'>Terms</Button>
            </Toolbar>

            <Dialog open={howItWorksDialogOpen} onClose={this.handleHowItWorksDialogClose} fullWidth maxWidth='md'>
              <div className={classes.videoWrapper}>
                <CardMedia
                  className={classes.video} component="iframe" src={`https://www.youtube.com/embed/${button.youtubeVideoId}`}
                  width="560" height="315" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                />
              </div>
            </Dialog>

            <AuthenticationDialog open={authenticationDialogOpen} onAuthenticate={this.handleAuthenticate} onClose={this.handleAuthenticationDialogClose}/>

          </Grid>
        </Grid>
      )
    }
  }
}


Show.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(withStyles(styles)(Show))
