import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Snackbar from '@material-ui/core/Snackbar'

import Intercom from '../../intercom'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main
  }
})

class ErrorBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
      onClose: props.onClose
    }
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({
      open: newProps.open
    })
  }

  handleClose = () => {
    this.setState({ open: false }, this.state.onClose())
  }

  render() {
    const { classes } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={this.state.open}
        autoHideDuration={null}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          'classes': {
            root: classes.root
          }
        }}
        message={
          <span id="message-id">
            <Link style={{ color: 'white', cursor: 'pointer' }} to='#' onClick={Intercom.show}>
              Unknown error, please contact CSV Loader support
            </Link>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon/>
          </IconButton>
        ]}
      />
    )
  }
}

ErrorBar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(ErrorBar)
