import React from 'react'
import { render } from 'react-dom'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Raven from 'raven-js'

import App from './components/app'
import ConsentShow from './components/consents/show'
import PasswordEdit from './components/passwords/edit'
import PasswordNew from './components/passwords/new'
import RegistrationNew from './components/registrations/new'
import SessionNew from './components/sessions/new'
import ToolIndex from './components/tools/index'

const CONTAINER_SELECTOR = '#webpack-app-container'

Raven.config('https://a7cc38ccc3f141a3a1df9097f0eab931@sentry.io/262927', {
  shouldSendCallback: () => {
    // a bit of a hack, better solution would be to check some sort of env variable
    return !window.location.href.startsWith('http://localhost:3000')
  }
}).install()

const primaryColor = '#FF00A8'
const primaryLightColor = '#ff87d8'
const secondaryColor = '#0E0E0E'

const theme = createMuiTheme({
  props: {
    MuiModal: {
      // this is needed to make sure that typing in intercom widget is working while dialogs are rendered on ui
      // see https://github.com/mui-org/material-ui/issues/17497 for details
      disableEnforceFocus: true
    }
  },
  palette: {
    primary: {
      main: primaryColor,
      light: primaryLightColor
    },
    secondary: { main: secondaryColor },
    background: {
      default: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: ['\'Open Sans\'', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Comfortaa', 'cursive'].join(',')
    },
    h2: {
      fontFamily: ['Comfortaa', 'cursive'].join(','),
      color: primaryColor
    },
    h3: {
      fontFamily: ['Comfortaa', 'cursive'].join(',')
    },
    h4: {
      fontFamily: ['Comfortaa', 'cursive'].join(',')
    },
    h5: {
      fontFamily: ['Comfortaa', 'cursive'].join(','),
      color: primaryColor,
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: ['Comfortaa', 'cursive'].join(',')
    }
  }
})

Raven.context(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const node = document.querySelector(CONTAINER_SELECTOR)

    render(
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <App>
            <Switch>
              <Route exact path='/tools' render={() => <ToolIndex/>}/>
              <Route exact path='/consent/:key' render={() => <ConsentShow/>}/>
              <Route exact path='/sign_up' render={() => <RegistrationNew/>}/>
              <Route exact path='/users/sign_up' render={() => <RegistrationNew/>}/>
              <Route exact path='/sign_in' render={() => <SessionNew/>}/>
              <Route exact path='/users/sign_in' render={() => <SessionNew/>}/>
              <Route exact path='/users/password/new' render={() => <PasswordNew/>}/>
              <Route exact path='/users/password/edit' render={() => <PasswordEdit/>}/>
            </Switch>
          </App>
        </MuiThemeProvider>
      </BrowserRouter>,
      node
    )
  })
})
