import apiHelper from './../../app/api/api_helper'

const api = apiHelper.connection()

const SessionsApi = {

  create: (user) => {
    return api.post('/users/sign_in', { data: { type: 'users', attributes: user } })
  }
}

export default SessionsApi
