import apiHelper from './../../app/api/api_helper'

const api = apiHelper.connection()

const RegistrationsApi = {

  create: (user) => {
    return api.post('/users', { data: { type: 'users', attributes: user } })
  }

}

export default RegistrationsApi
