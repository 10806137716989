import IconButton from '@material-ui/core/IconButton/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import React from 'react'

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})


const DialogCloseButton = (props) => {
  const { classes, onClick } = props

  return (
    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClick}>
      <CloseIcon/>
    </IconButton>
  )
}

DialogCloseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(DialogCloseButton)
