import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
// eslint-disable-next-line import/no-unresolved
import Logo from 'images/logo-white.png'

import ErrorBoundary from '../../app/components/base/error_boundary'

const styles = theme => ({
  flex: {
    flex: 1
  },
  header: {
    marginBottom: theme.spacing(4)
  },
  logo: {
    height: 28
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(12)
  }
})

class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  renderNaked = () => {
    const { location } = this.props

    return _.some(['/consent/:key'], (path) => {
      return !_.isNull(matchPath(location.pathname, { path, exact: true, strict: false }))
    })
  }

  render() {
    const { children, classes } = this.props

    let appBar = null
    if (!this.renderNaked()) {
      appBar = (
        <AppBar position="fixed" color="secondary" className={classes.header}>
          <Toolbar>
            <Typography variant='h6' className={classes.flex}>
              <img src={Logo} className={classes.logo} alt="CSV Loader"/>
            </Typography>
          </Toolbar>
        </AppBar>
      )
    }

    return (
      <div id="app">
        <ErrorBoundary>
          {appBar}
          <div className={classes.content}>{children}</div>
        </ErrorBoundary>
      </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired
}

export default withRouter(withStyles(styles)(App))
