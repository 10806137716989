import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import Raven from 'raven-js'

import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import RegistrationsApi from '../../../api/registrations_api'
import DialogCloseButton from '../../../../app/components/base/dialog_close_button'
import SessionsApi from '../../../api/sessions_api'

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.secondary
  },
  switchFlowSection: {
    marginTop: theme.spacing(2)
  }
})

class AuthenticationDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = { signUpFlow: true, fullName: '', email: '', password: '', newsletter: false, formSubmitted: false, errors: {} }
  }

  toggleFlowToSignUp = (event) => {
    event.preventDefault()
    this.setState({ signUpFlow: true, fullName: '', email: '', password: '', newsletter: false, errors: {} })
  }

  toggleFlowToSignIn = (event) => {
    event.preventDefault()
    this.setState({ signUpFlow: false, fullName: '', email: '', password: '', newsletter: false, errors: {} })
  }

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value })
  }

  handleChangeNewsletter = (event) => {
    this.setState({ newsletter: event.target.checked })
  }

  handleSignUp = (event) => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    RegistrationsApi.create(
      {
        'full-name': this.state.fullName,
        'email': this.state.email,
        'password': this.state.password,
        'newsletter': this.state.newsletter
      }
    ).then(({ data }) => {
      this.props.onAuthenticate(data)
    }).catch((error) => {
      if (error.response) {
        this.setState({ formSubmitted: false, errors: error.response.data })
      } else {
        this.setState({ errors: { base: error.toString() }, formSubmitted: false })
        Raven.captureException(error, { extra: error })
      }
    })
  }

  handleSignIn = (event) => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    SessionsApi.create(
      {
        email: this.state.email,
        password: this.state.password
      }
    ).then(({ data }) => {
      this.props.onAuthenticate(data)
    }).catch((error) => {
      if (error.response) {
        this.setState({ errors: { base: error.response.data.Error }, formSubmitted: false })
      } else {
        this.setState({ errors: { base: error.toString() }, formSubmitted: false })
        Raven.captureException(error, { extra: error })
      }
    })
  }


  render = () => {
    const { open, onClose, classes } = this.props
    const { signUpFlow, formSubmitted, errors } = this.state

    if (signUpFlow) {
      return (
        <Dialog open={open} onClose={onClose} disableBackdropClick fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <form name="session" onSubmit={this.handleSignUp}>
            <DialogTitle id="alert-dialog-title">
              Sign Up
              <DialogCloseButton onClick={onClose}/>
            </DialogTitle>
            <DialogContent>
              {!!errors.base && <Typography color="error" paragraph>{errors.base}</Typography>}

              <TextField
                key="fullname" name="fullName" label="Your Name" margin="dense" disabled={formSubmitted} fullWidth required value={this.state.fullName}
                error={!!errors.fullName} helperText={errors.fullName}
                onChange={this.handleChange('fullName')}
              />
              <TextField
                key="email" name="email" label="Your Email Address" margin="dense" type="email" disabled={formSubmitted} fullWidth required
                value={this.state.email}
                error={!!errors.email} helperText={errors.email}
                onChange={this.handleChange('email')} autoComplete="off"
              />
              <TextField
                key="password" name="password" label="Password" margin="dense" type="password" disabled={formSubmitted} fullWidth required
                value={this.state.password}
                error={!!errors.password} helperText={errors.password}
                onChange={this.handleChange('password')} autoComplete="new-password"
              />

              <FormControl fullWidth margin="dense">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={formSubmitted}
                        checked={this.state.newsletter}
                        onChange={this.handleChangeNewsletter}
                        value="newsletter"
                        color="primary"
                      />
                    }
                    label="Send me occasional product updates (approx. once per month)"
                    classes={{ label: classes.formLabel }}
                  />
                </FormGroup>
              </FormControl>

              <DialogContentText>
                Already have an account? <Link href='#' onClick={this.toggleFlowToSignIn}>Sign in</Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" disabled={formSubmitted}>
                Sign Up
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )
    } else {
      return (
        <Dialog open={open} onClose={onClose} disableBackdropClick fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <form name="session" onSubmit={this.handleSignIn}>
            <DialogTitle id="alert-dialog-title">
              Sign In
              <DialogCloseButton onClick={onClose}/>
            </DialogTitle>
            <DialogContent>
              {!!errors.base && <Typography color="error" paragraph>{errors.base}</Typography>}

              <TextField
                key="email" name="email" label="Your Email Address" margin="dense" type="email" error={!!errors.email}
                disabled={this.state.formSubmitted} fullWidth required value={this.state.email}
                onChange={this.handleChange('email')}
              />
              <TextField
                key="password" name="password" label="Password" margin="dense" type="password" error={!!errors.password}
                disabled={this.state.formSubmitted} fullWidth required value={this.state.password}
                onChange={this.handleChange('password')}
              />

              <DialogContentText className={classes.switchFlowSection}>
                Need an CSV Loader
                account? <Link href='#' onClick={this.toggleFlowToSignUp}>Sign up</Link> • <Link component={RouterLink} to='/users/password/new'>Forgot
                password?</Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>

              <Button type="submit" color="primary" disabled={this.state.formSubmitted}>
                Sign In
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )
    }
  }
}

AuthenticationDialog.propTypes = {
  onAuthenticate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(AuthenticationDialog))
